<template>
	<b-card title="Checkout" class="mt-3">
		<b-form>
			<!-- PAYMENT OF TEXT -->
			<p class="font-weight-bold text-danger">Payment of {{ grandTotal | rupeeCurrency }}</p>

			<hr />

			<!-- Heading for delivery location -->
			<p>
				<b>
					Select a Delivery Address / Pick-up from store
				</b>
			</p>

			<!-- Button to set pickup -->
			<div class="mb-3">
				<b-button size="sm" v-if="form.addressId" @click.prevent="form.addressId = null" variant="primary" class="text-white">
					Pick-up from store
				</b-button>
				<b-alert v-else variant="info" class="small font-weight-bold" show>
					Pick up from store is selected
				</b-alert>
			</div>

			<!-- Show list of address, with recent one first -->
			<template v-if="count > 0">
				<p>Yours Addresses</p>
				<b-row>
					<b-card-group class="col-sm-6 col-md-4 col-xl-3" v-for="userAddress in sortedUserAddresses" :key="userAddress.id">
						<AddressCard :userAddress="userAddress" class="mb-3" @removeSuccess="() => removeSuccess(userAddress)" :border-variant="isSelectedAddress(userAddress) ? `warning` : ``">
							<template v-slot:footer>
								<b-button size="sm" class="mb-2" v-if="!isSelectedAddress(userAddress)" @click.prevent="() => setDeliveryAddress(userAddress)">
									Deliver to this address
								</b-button>

								<b-alert variant="info" :show="isSelectedAddress(userAddress)" class="small font-weight-bold">
									Current address set for delivery
								</b-alert>
							</template>
						</AddressCard>
					</b-card-group>
				</b-row>
			</template>

			<!-- Add an address option -->
			<b-row>
				<b-col col offset-sm="2" sm="8" offset-lg="3" lg="6">
					<b-button @click.prevent="addAddress" size="sm" block squared>
						Add New Address
					</b-button>
				</b-col>
			</b-row>

			<hr />

			<!-- Payment Method -->
			<b-form-group label="Payment Method" label-class="text-muted">
				<b-form-radio-group v-model="paymentMethod">
					<b-form-radio value="cod">Cash/UPI on delivery</b-form-radio>
				</b-form-radio-group>
			</b-form-group>

			<!-- Place Order Button -->
			<b-button @click="onPlaceOrderClicked" variant="warning" class="text-uppercase mt-5" size="lg" block pill> <b-icon-arrow-right scale="0.7" />Place Order </b-button>
		</b-form>
	</b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getAddresses, placeOrder } from "../../../../apis/user";

import AddressCard from "../../../../components/account/address/AddressCard.vue";

export default {
	components: { AddressCard },
	created() {
		const token = this.token;
		if (!token) {
			// redirect to checkout login
			console.log("redirect to checkout login");
			return;
		}
		if (!this.storeUrlName || !this.cartId) {
			this.$router.push({ name: "Cart" });
			return;
		}
		this.getCartApi().then((data) => {
			this.cart = { ...data };
		});
		// to init user details if token present
		this.initUserDetails();
	},
	data() {
		return {
			paymentMethod: "cod",
			form: {
				addressId: null,
			},
			errors: null,
			cart: {},

			userAddresses: [],
		};
	},
	computed: {
		...mapGetters({
			store: "guest/store/getStore",
			storeUrlName: "guest/store/getStoreUrlName",
			token: "login/getToken",
			getCartId: "user/cart/getCartId",
		}),
		cartId() {
			if (this.storeUrlName) {
				return this.getCartId(this.storeUrlName);
			}
			return null;
		},
		grandTotal() {
			if (this.cart) {
				const totalAmount = this.cart.totalAmount;
				return parseFloat(totalAmount).toFixed(2);
			}
			return 0;
		},
		count() {
			return this.userAddresses.length;
		},
		sortedUserAddresses() {
			const addresses = [...this.userAddresses];
			addresses.sort((address1, address2) => {
				const time1 = address1.lastUsedTime ? address1.lastUsedTime : 0;
				const time2 = address2.lastUsedTime ? address2.lastUsedTime : 0;
				return time2 - time1;
			});
			return addresses;
		},
	},
	watch: {
		store: function() {
			this.initUserDetails();
		},
	},
	methods: {
		...mapActions({
			resetCartItems: "user/cartItems/reset",
			resetCartPrescriptions: "user/userCartPrescriptions/reset",
			initNewCart: "user/cart/initNewCart",
			getCartApi: "user/cart/getCart",
		}),
		initUserDetails() {
			getAddresses(this.token).then((data) => {
				this.userAddresses = data;
			});
		},
		isSelectedAddress(userAddress) {
			return this.form.addressId == userAddress.id;
		},
		setDeliveryAddress(userAddress) {
			this.form.addressId = userAddress.id;
		},
		removeSuccess(userAddress) {
			this.removeItemFromList(this.userAddresses, userAddress, "id");

			if (this.form.addressId == userAddress.id) {
				this.form.addressId = null;
			}
		},
		onPlaceOrderClicked() {
			const storeUrlName = this.storeUrlName;
			const addressId = this.form.addressId;
			placeOrder(storeUrlName, addressId)
				.then(this.onPlaceOrderSuccess)
				.catch(({ data }) => {
					this.errors = data.errors;
				});
		},
		addAddress() {
			// redirect to form for new
			this.$router.push({ name: "UserAddressForm" });
		},
		onPlaceOrderSuccess(data) {
			const storeUrlName = this.storeUrlName;
			const orderId = data.id;
			// reset cart items
			this.resetCartItems();
			// Remove prescriptions
			this.resetCartPrescriptions();
			// re-init new cart
			this.initNewCart({ storeUrlName });
			// Go To Success Page
			this.$router.push({ name: "CartSuccessPlaceOrder", query: { orderId } });
		},
	},
};
</script>
